import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contact_45successJtmrpHVWIjMeta } from "/vercel/path0/pages/contact-success.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsxqIDtGMklEMeta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meetingform_45successjXA57QPQ5KMeta } from "/vercel/path0/pages/meetingform-success.vue?macro=true";
import { default as meetingformyqklQBmRwxMeta } from "/vercel/path0/pages/meetingform.vue?macro=true";
import { default as _91item_93VS61Z44hIgMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as index1us1AaJFa5Meta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as reviewsp0OUKSelxuMeta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as roomstL5659IyVgMeta } from "/vercel/path0/pages/rooms.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___en",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___nl",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/nl/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93plMEWPrPAfMeta?.name ?? "page___es",
    path: _91_46_46_46page_93plMEWPrPAfMeta?.path ?? "/es/:page(.*)*",
    meta: _91_46_46_46page_93plMEWPrPAfMeta || {},
    alias: _91_46_46_46page_93plMEWPrPAfMeta?.alias || [],
    redirect: _91_46_46_46page_93plMEWPrPAfMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___en",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___nl",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/nl/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contact_45successJtmrpHVWIjMeta?.name ?? "contact-success___es",
    path: contact_45successJtmrpHVWIjMeta?.path ?? "/es/contact-success",
    meta: contact_45successJtmrpHVWIjMeta || {},
    alias: contact_45successJtmrpHVWIjMeta?.alias || [],
    redirect: contact_45successJtmrpHVWIjMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___en",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___nl",
    path: contactJnsDpYVejzMeta?.path ?? "/nl/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact___es",
    path: contactJnsDpYVejzMeta?.path ?? "/es/contacto",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___en",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___nl",
    path: faqM4LN7jxnMuMeta?.path ?? "/nl/veelgestelde-vragen",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq___es",
    path: faqM4LN7jxnMuMeta?.path ?? "/es/preguntas-frecuentes",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___en",
    path: galleryjDl5RbNP1cMeta?.path ?? "/gallery",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___nl",
    path: galleryjDl5RbNP1cMeta?.path ?? "/nl/galerij",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryjDl5RbNP1cMeta?.name ?? "gallery___es",
    path: galleryjDl5RbNP1cMeta?.path ?? "/es/galeria",
    meta: galleryjDl5RbNP1cMeta || {},
    alias: galleryjDl5RbNP1cMeta?.alias || [],
    redirect: galleryjDl5RbNP1cMeta?.redirect,
    component: () => import("/vercel/path0/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___en",
    path: highlightsxqIDtGMklEMeta?.path ?? "/location",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___nl",
    path: highlightsxqIDtGMklEMeta?.path ?? "/nl/locatie",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: highlightsxqIDtGMklEMeta?.name ?? "highlights___es",
    path: highlightsxqIDtGMklEMeta?.path ?? "/es/ubicacion",
    meta: highlightsxqIDtGMklEMeta || {},
    alias: highlightsxqIDtGMklEMeta?.alias || [],
    redirect: highlightsxqIDtGMklEMeta?.redirect,
    component: () => import("/vercel/path0/pages/highlights.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___nl",
    path: indexe9Brt5DfdhMeta?.path ?? "/nl",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___es",
    path: indexe9Brt5DfdhMeta?.path ?? "/es",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: meetingform_45successjXA57QPQ5KMeta?.name ?? "meetingform-success___en",
    path: meetingform_45successjXA57QPQ5KMeta?.path ?? "/meetingform-success",
    meta: meetingform_45successjXA57QPQ5KMeta || {},
    alias: meetingform_45successjXA57QPQ5KMeta?.alias || [],
    redirect: meetingform_45successjXA57QPQ5KMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: meetingform_45successjXA57QPQ5KMeta?.name ?? "meetingform-success___nl",
    path: meetingform_45successjXA57QPQ5KMeta?.path ?? "/nl/meetingform-success",
    meta: meetingform_45successjXA57QPQ5KMeta || {},
    alias: meetingform_45successjXA57QPQ5KMeta?.alias || [],
    redirect: meetingform_45successjXA57QPQ5KMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: meetingform_45successjXA57QPQ5KMeta?.name ?? "meetingform-success___es",
    path: meetingform_45successjXA57QPQ5KMeta?.path ?? "/es/meetingform-success",
    meta: meetingform_45successjXA57QPQ5KMeta || {},
    alias: meetingform_45successjXA57QPQ5KMeta?.alias || [],
    redirect: meetingform_45successjXA57QPQ5KMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform-success.vue").then(m => m.default || m)
  },
  {
    name: meetingformyqklQBmRwxMeta?.name ?? "meetingform___en",
    path: meetingformyqklQBmRwxMeta?.path ?? "/meetingform",
    meta: meetingformyqklQBmRwxMeta || {},
    alias: meetingformyqklQBmRwxMeta?.alias || [],
    redirect: meetingformyqklQBmRwxMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: meetingformyqklQBmRwxMeta?.name ?? "meetingform___nl",
    path: meetingformyqklQBmRwxMeta?.path ?? "/nl/meetingform",
    meta: meetingformyqklQBmRwxMeta || {},
    alias: meetingformyqklQBmRwxMeta?.alias || [],
    redirect: meetingformyqklQBmRwxMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: meetingformyqklQBmRwxMeta?.name ?? "meetingform___es",
    path: meetingformyqklQBmRwxMeta?.path ?? "/es/meetingform",
    meta: meetingformyqklQBmRwxMeta || {},
    alias: meetingformyqklQBmRwxMeta?.alias || [],
    redirect: meetingformyqklQBmRwxMeta?.redirect,
    component: () => import("/vercel/path0/pages/meetingform.vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___en",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/special-offers/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___nl",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/nl/arrangementen/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: _91item_93VS61Z44hIgMeta?.name ?? "packages-item___es",
    path: _91item_93VS61Z44hIgMeta?.path ?? "/es/arreglos/:item()",
    meta: _91item_93VS61Z44hIgMeta || {},
    alias: _91item_93VS61Z44hIgMeta?.alias || [],
    redirect: _91item_93VS61Z44hIgMeta?.redirect,
    component: () => import("/vercel/path0/pages/packages/[item].vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___en",
    path: index1us1AaJFa5Meta?.path ?? "/special-offers",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___nl",
    path: index1us1AaJFa5Meta?.path ?? "/nl/arrangementen",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: index1us1AaJFa5Meta?.name ?? "packages___es",
    path: index1us1AaJFa5Meta?.path ?? "/es/arreglos",
    meta: index1us1AaJFa5Meta || {},
    alias: index1us1AaJFa5Meta?.alias || [],
    redirect: index1us1AaJFa5Meta?.redirect,
    component: () => import("/vercel/path0/pages/packages/index.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy___en",
    path: privacykJ3SQnmrN4Meta?.path ?? "/privacy-policy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy___nl",
    path: privacykJ3SQnmrN4Meta?.path ?? "/nl/privacy-policy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy___es",
    path: privacykJ3SQnmrN4Meta?.path ?? "/es/privacy-policy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___en",
    path: reviewsp0OUKSelxuMeta?.path ?? "/reviews",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___nl",
    path: reviewsp0OUKSelxuMeta?.path ?? "/nl/beoordelingen",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: reviewsp0OUKSelxuMeta?.name ?? "reviews___es",
    path: reviewsp0OUKSelxuMeta?.path ?? "/es/resenas",
    meta: reviewsp0OUKSelxuMeta || {},
    alias: reviewsp0OUKSelxuMeta?.alias || [],
    redirect: reviewsp0OUKSelxuMeta?.redirect,
    component: () => import("/vercel/path0/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: roomstL5659IyVgMeta?.name ?? "rooms___en",
    path: roomstL5659IyVgMeta?.path ?? "/rooms",
    meta: roomstL5659IyVgMeta || {},
    alias: roomstL5659IyVgMeta?.alias || [],
    redirect: roomstL5659IyVgMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: roomstL5659IyVgMeta?.name ?? "rooms___nl",
    path: roomstL5659IyVgMeta?.path ?? "/nl/kamers",
    meta: roomstL5659IyVgMeta || {},
    alias: roomstL5659IyVgMeta?.alias || [],
    redirect: roomstL5659IyVgMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: roomstL5659IyVgMeta?.name ?? "rooms___es",
    path: roomstL5659IyVgMeta?.path ?? "/es/habitaciones",
    meta: roomstL5659IyVgMeta || {},
    alias: roomstL5659IyVgMeta?.alias || [],
    redirect: roomstL5659IyVgMeta?.redirect,
    component: () => import("/vercel/path0/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___en",
    path: sitemapmtRscZaA8vMeta?.path ?? "/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___nl",
    path: sitemapmtRscZaA8vMeta?.path ?? "/nl/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapmtRscZaA8vMeta?.name ?? "sitemap___es",
    path: sitemapmtRscZaA8vMeta?.path ?? "/es/sitemap",
    meta: sitemapmtRscZaA8vMeta || {},
    alias: sitemapmtRscZaA8vMeta?.alias || [],
    redirect: sitemapmtRscZaA8vMeta?.redirect,
    component: () => import("/vercel/path0/pages/sitemap.vue").then(m => m.default || m)
  }
]