/**
 * Loads redirects from the API.
 *
 * @returns {Object} with redirects and fetch method.
 */
export const useRedirects = () => {
	const redirects = useState('redirects', () => { });

	const setRedirects = (data) => {
		redirects.value = data;
	};

	let data = { data: null };
	const fetchRedirects = async () => {
		try {
			data = await useWebsiteFetch('redirects');
			setRedirects(data.data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return {
		redirects,
		fetchRedirects,
	};
};
